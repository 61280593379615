@import "materialize/materialize.scss";
nav {
	height: 110px;
	line-height: 110px;
}

nav ul a,
nav .brand-logo {
  color: #162d50;
}

h1,h2 {
	font-weight: bold;
	color: #162d50;
}

h3,h4,h5,h6 {
	color: #162d50;
}


#front-page-logo {
	height: 100px;
}

.parallax-container {
	height: 350px;
}

.card .card-content .card-title {
	font-weight: bold;
}

.card .card-title {
	font-size: 34px;
	font-weight: 300;
  }


.patreon-ad {
	position:fixed;
	left:0;
	bottom:0;
	width:299px;
	background-color:#fff;
	z-index:1000;
	border-top:1px solid rgba(0,0,0,0.14)
   }
.patreon-ad::before {
	position:absolute;
	top:0;
	left:20px;
	-webkit-transform:translateY(-50%);
	transform:translateY(-50%);
	padding:4px 6px;
	border-radius:3px;
	background-color:#ee6e73;
	color:#fff;
	content:"Sponsor";
	text-transform:uppercase;
	letter-spacing:.4px;
	font-weight:600;
	font-size:10px;
	line-height:1;
	z-index:2
   }
   .patreon-ad a {
	width:100%;
	line-height:45px
   }
   .patreon-ad img {
	display:block;
	width:100%
   }
   .patreon-footer-ad {
	display:inline-block;
	float:left;
	margin-right:50px;
	margin-bottom:20px
   }
   .patreon-footer-ad.white-ad {
	background-color:#444;
	border-radius:4px;
	padding:0 10px
   }
   .patreon-footer-ad:last-child {
	margin-right:0
   }
   .patreon-footer-ad a {
	display:-webkit-box;
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-webkit-align-items:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	-ms-flex-pack:center;
	justify-content:center;
	height:70px
   }
   .patreon-footer-ad img {
	max-width:350px;
	max-height:100%
   }
   @media only screen and (max-width: 992px) {
	.patreon-ad,
	.bsa-cpc {
	 display:none
	}
	.patreon-footer-ad {
	 display:block;
	 float:none;
	 margin-right:0
	}
	footer.page-footer .container {
	 text-align:center
	}
   }